<script>
import { removeAllCookies } from '@/common/helpers/cookies';

export default {
  name: 'RevokeToken',
  beforeRouteEnter() {
    removeAllCookies();
    window.location.reload();
  },
};
</script>
